.users-container {
    width: inherit;
    height: 80svh;
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: center;
}

.users-header-buttons {
    width: calc(100% - 1rem);
    margin: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
}

.add-user-btn, .reset-password-btn {
    color: #EA723C;
    text-transform: none;
    border: 1px solid #EA723C;
    border-radius: 12px;
    min-height: 2.5rem;
    max-width: 45%;
}

.add-user-btn.disabled {
    color: lightgray;
    border: 1px solid lightgray;
}

.add-user-btn .adduser-svg-icon path{
    fill : #EA723C;
    transition: fill ease-in-out 300ms
}
.add-user-btn.disabled .adduser-svg-icon path{
    fill : lightgray;
}
.add-user-btn:hover .adduser-svg-icon path{
    fill : white
}

.add-user-btn:hover, .reset-password-btn:hover {
    color: white;
}

.resetpassword-svg-icon path{
    transition: fill ease-in-out 300ms, stroke ease-in-out 300ms;
}

.reset-password-btn:hover .resetpassword-svg-icon .stroke-based{
    stroke : white;
}
.reset-password-btn:hover .resetpassword-svg-icon .fill-based{
    fill : white;
}

.action-svg-icon * {
    transition: fill ease-in-out 300ms, stroke ease-in-out 300ms;
}

.action-svg-icon path:not(.fill-based) {
    stroke: #232322;
}
.action-svg-icon rect, .action-svg-icon path.fill-based {
    fill: #232322;
}

.action-btn:hover .action-svg-icon path:not(.fill-based) {
    stroke: #EA723C;
}
.action-btn:hover .action-svg-icon rect, .action-btn:hover .action-svg-icon path.fill-based {
    fill: #EA723C;
}

.add-user-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-user-field {
    width: 90%;
}

@media screen and (max-width:768px) {
    .users-container {
        width: 100%;
        height: calc(100svh - 4rem - 2.5svh - 1rem - 3rem);
        padding: 0rem;
    }

    .users-header-buttons {
        padding: 0;
        margin: 0.5rem 0;
        width: 100%;
    }
}