.archive-container {
    width: inherit;
    height: 80svh;
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.archive-header {
    width: 100%;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.archive-search-bar {
    display: flex;
    padding: 0rem;
}

@media screen and (max-width:768px) {
    .archive-container {
        width: 100%;
        height: calc(100svh - 4rem - 2.5svh - 1rem - 3rem);
        padding: 0; /*overwriting*/
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}