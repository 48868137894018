.upload-status-container-wrapper {
    position: absolute;
    bottom: 0.1rem;
    right: 1.5rem;
    width: 26rem;
    max-width: calc(100% - 2rem);
    height: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 1251;
    overflow: hidden;
    border-radius: 0.5rem;
    background-color: transparent;
}

.upload-status-container {
    /* position: absolute;
    bottom: 0.5rem;
    right: 10%; */
    width: 25rem;
    max-width: 100%;
    max-height: 20rem;
    height: fit-content;
    background-color: whitesmoke;
    box-shadow: 0px 0px 8px 1px lightgray;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    padding: 0.4rem;
    position: relative;
}

.upload-status-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border-bottom: 1px solid gray; */
    padding: 0.5rem;
}

.upload-status-item.failed {
    cursor: pointer;
}

.upload-status-item.failed:hover {
    background-color: #EBEBEB;
}

@media screen and (max-width:600px) {
    .upload-status-container-wrapper {
        width: 95%;
        position: absolute;
        left: 2.5%;
        box-sizing: border-box;
    }

    .upload-status-container {
        width: 100%;
        box-sizing: border-box;
    }
}