.jd-page-container {
    width: inherit;
    height: 80svh;
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jd-header {
    width: calc(100% - 1rem);
    margin: 1rem 0.5rem 0.8rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 10%;
}

.jd-page-container hr {
    margin: 0;
    width: calc(100% + 2rem);
    height: 1px;
    margin-left: -1rem;
    border: none;
    border-top: 1px solid #B6BFCE96;
}

.jd-header-title {
    font-size: 1.2rem;
    font-weight: 600;
}

.jd-header-right-side {
    display: flex;
    align-items: center;
}

.jd-header-more-button {
    margin: 0rem 0.8rem;
}

.jd-page-content-box {
    width: 100%;
    height: 90%;
    padding: 0.5rem 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.jd-page-content-top {
    height: calc(70% - 0.25rem);
    display: flex;
    justify-content: space-between;
}

.jd-page-content-bottom {
    height: calc(30% - 0.25rem);
    display: flex;
    justify-content: space-between;
}

.jd-page-content-bottom .quick-link-cards {
    width: calc(100%/3 - 0.25rem);
    height: calc(100% - 0.25rem);
    max-height: 10rem;
}

.graph-box, .tools-box {
    width: calc(100%/2 - 0.25rem);
}

.tools-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.graph-box {
    padding: 0.5rem;
    border-radius: 1.5rem;
    box-sizing: border-box;
    background-color: #EA723C14;
    border: 1px solid;
    border-color: #EA723C30;
    position: relative;
}

.tools-cards {
    height: calc(35% - 0.25rem);
    display: flex;
    justify-content: space-between;
}

.link-card {
    width: calc(50% - 0.25rem);
    border-radius: 1.1rem;
    padding: 0.75rem;
    box-sizing: border-box;
    background-color: #D9D9D9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color ease-in-out 200ms;
}

.link-card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.link-card-icon {
    height: 1.25rem;
    min-height: 1rem;
    width: fit-content;
    padding: 0.3rem;
    border-radius: 8px;
    transition: color ease-in-out 200ms, background-color ease-in-out 200ms, opacity ease-in-out 200ms;
}

.link-card-icon * {
    transition: stroke ease-in-out 200ms;
}

.link-card-middle {
    font-size: 0.9rem;
    font-weight: 600;
}

.link-card-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.likn-card-bottom-text {
    font-size: 0.65rem;
    max-width: 65%;
}

.rna-card {
    width: calc(50% - 0.25rem);
}

.candidates-list {
    height: calc(65% - 0.25rem);
    border-radius: 1.1rem;
    box-sizing: border-box;
    background-color: #00D4FF14;
    padding: 0rem 0.5rem 0rem;
    overflow-y: auto;
    border: 1px solid transparent;
    transition: background-color ease-in-out 300ms;
}

.candidates-list:hover {
    background-color: #00D4FF31;
}

.card-resume-count {
    font-size: 0.9rem;
    color: #1E1E1E;
}

.card-resume-count span {
    font-weight: 600;
    font-size: 1rem;
}

.candidates-list-header {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    position: sticky;
    top: 0;
    z-index: 1;
}

.candidates-list-header::before {
    content: '';
    background: #00D4FF14;
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.candidates-list-header a {
    text-decoration: none;
    color: darkcyan;
    font-size: 0.8rem;
}

.candidates-list-item {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0rem;
    box-sizing: border-box;
    border-bottom: 1px solid #B6BFCE96;
}

.input-container {
    width: 9.5rem;
}

.interval-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0.25rem;
    height: 2rem;
}

@media screen and (max-width:768px) {
    .jd-page-container {
        width: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .jd-header {
        margin: 0.5rem;
        flex-direction: column;
    }

    .jd-page-container br {
        display: none;
    }

    .jd-header-more-button {
        margin: 0.8rem;
    }

    .jd-page-content-box {
        height: unset;
    }

    .jd-page-content-top {
        height: unset;
        display: block;
    }  
    
    .jd-page-content-bottom {
        height: unset;
        display: block;
    }
    
    .jd-page-content-bottom .quick-link-cards {
        width: 100%;
        height: max-content;
        margin: 0.5rem 0rem;
    }    

    .graph-box, .tools-box {
        width: 100%;
        margin-top: 0.5rem;
    }

    .graph-box {
        height: 15rem;
    }

    .candidates-list {
        margin-top: 0.5rem;
    }
}

@media screen and (max-width:500px) {
    .tools-cards {
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }

    .tools-cards .link-card, .tools-cards .rna-card {
        width: 100%;
        margin: 0.25rem;
        min-height: 8rem;
    }
}

@media screen and (min-width:768px) and (min-height:1100px) {
    .jd-page-content-top {
        width: 100%;
        flex-direction: column;
    }

    .graph-box, .tools-box {
        flex-direction: row;
        width: 100%;
        height: calc(100%/2 - 0.25rem);
    }

    .tools-cards {
        flex-direction: column;
        width: calc(35% - 0.25rem);
        height: 100%;
    }

    .tools-cards .link-card, .tools-cards .rna-card {
        width: 100%;
        flex: 1;
    }

    .candidates-list {
        width: calc(65% - 0.25rem);
        height: 100%;
    }
}