.dashboard-icon {
    width: 100%;
    max-width: 20px;
    max-height: 20px;
}

.dashboard-box {
    box-sizing: border-box;
    padding: 0rem 1rem;
}

.app-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    line-height: 0.85rem;
    height: 100%;
}

.app-logo-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

.app-logo-subtitle {
    align-self: flex-end;
    font-weight: 600;
    font-variant: monospace;
    font-size: 0.45rem;
}

.sidebar-footer {
    width: 100%;
    height: "1rem";
    padding: 1rem;
    overflow: hidden;
    box-sizing: border-box;
}

.dashboard-box-header {
    height: calc(5% - 0.75rem);
    padding: 0rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-box-header-user {
    text-align: right;
    text-transform: capitalize;
    line-height: 1.2rem;
    margin: 0rem 0.5rem;
}

.dahsboard-box-header-user-name {
    font-size: 1rem;
    font-weight: 600;
}

.dahsboard-box-header-user-role {
    color: #6D6D6D;
    font-size: 0.85rem;
}
