.web-careers-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.web-careers-form {
    width: 90%;
    max-width: 50rem;
    height: 90%;
    border-radius: 1rem;
    /* overflow-y: auto; */
    position: relative;
    border: 1px solid black;
}

.web-careers-form .submitted-msg {
    width: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
}

.web-careers-form header {
    background-color: #EA723C80;
    font-size: 1.5rem;
    border-radius: 1rem 1rem 0rem 0rem;
    font-weight: 600;
    width: 100%;
    height: fit-content;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.web-careers-fields {
    padding: 1rem 2rem 0.5rem;
    box-sizing: border-box;
    flex: 1;
    height: inherit;
    overflow-y: auto;
}

.web-careers-file-input {
    margin: 0.5rem 0rem;
}

.required {
    color: red;
}

.web-careers-section {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
}

.web-careers-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.web-careers-add-more-icon {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: lightgray;
}

.education-details-item {
    padding: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.education-details-item .right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.education-details-iname {
    font-size: 1.1rem;
    font-weight: 500;
    color: black;
    margin: 0.1rem 0rem;
}
.education-details-degree {
    font-size: 1rem;
    font-weight: 500;
    color: gray;
}
.education-details-duration {
    font-size: 0.75rem;
    font-weight: 400;
    color: gray;
}

@media screen and (max-width:768px) {
    .web-careers-section {
        grid-template-columns: auto;
        column-gap: 0rem;
    }
}