.dig-section {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
}

.dig-header span {
    font-size: 1rem;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
}

.dig-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dig-add-more-icon {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: lightgray;
}

.dig-details {
    transition: height ease-in-out 300ms;
}

.dig-details-item {
    padding: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dig-details-item .right {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:768px) {
    .dig-section {
        grid-template-columns: auto;
        column-gap: 0rem;
    }
}