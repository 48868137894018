.chip {
    padding: 0;
    margin: 0.1rem;
    font-size: 0.85rem;
    text-transform: capitalize;
}

.chipContainer {
    width: 100%;
}

.timelineContent {
    width: 100%;
}

.timelineHeader {
    font-size: 0.9rem;
}

.oppositeHeader {
    font-size: 0.85rem;
    color: rgb(100, 100, 100);
}

.oppositeSubHeader {
    font-size: 0.7rem;
    color: steelblue;
}

.timelineSubHeader {
    font-size: 0.75rem;
    color: darkgrey;
}
