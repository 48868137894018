.container {
    background-color: transparent; 
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    max-height: 100%;
    text-align: center;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 65%;
    margin: 0 auto;
    padding: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); 
    border-radius: 12px;
}

.error-message {
    margin-bottom: 20px;
}

.error-code {
    font-size: 6em; 
    color: #EA723C; 
    margin: 0;
}

.error-title {
    font-size: 2em; 
    margin: 10px 0;
    font-weight: 500; 
}

.error-description {
    font-size: 1.2em; 
    margin: 20px 0;
    color: #555; 
}

.suggestion {
    font-size: 1em; 
    margin: 10px 0;
}

.home-button {
    display: inline-block;
    background-color: #EA723C; 
    color: #ffffff;
    padding: 10px 20px; 
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 1em;
    font-weight: 500; 
}

.home-button:hover {
    background-color: #d56a31; 
    transform: scale(1.05); 
}

.error-image {
    max-width: 100%; 
    height: auto; 
}

@media screen and (max-width:768px) {
    .container { 
        height: calc(100svh - 4rem - 2.5svh - 1rem - 3rem);
    }
}