.fill-button-container{
    cursor: pointer;
    position: relative;
    overflow: hidden;
    user-select: none;
    background-color: transparent;
}

.fill-button-container.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.fill-button-container .content {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0rem 0.5rem;
}

.fill-button-container p {
    z-index: 2;
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 600;
    transition: color ease-in-out 200ms;
}

.fill-button-liquid::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #EA723C;
    transform-origin: left;
    z-index: 1;
    transform: scaleX(0);
    /* border-radius: 2rem; */
    transition: transform ease-in-out 300ms;
}

.fill-button-container:not(.disabled):hover .fill-button-liquid::before {
    transform: scaleX(1);
    z-index: 0;
}

@media screen and (max-width:768px) {
    .fill-button-container p {
        font-size: 0.8rem;
    }
}