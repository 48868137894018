.ag-header-cell-label {
    justify-content: center;
    font-weight: 500;
    color: #1E1E1E80;
    font-size: 1rem;
}

.ag-theme-quartz {
    --ag-active-color : #EA723C;
    --ag-font-family: "Montserrat";
    --ag-header-background-color: white;
}


.ag-root .ag-cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    color: #4D4C4C;
}

.ag-theme-quartz.mobile .ag-paging-panel .ag-paging-page-size, .ag-theme-quartz.mobile .ag-paging-panel .ag-paging-row-summary-panel {
    display: none;
}

.ag-root-wrapper {
    border: none;
}

.user-role {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
}

.centered-cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.name-cell {
    --ag-row-height:60px;
}


.not-centered-header-cell .ag-header-cell-label {
    justify-content: flex-start;
}