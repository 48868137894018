.reset-password-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-password-form {
    width: 90%;
    max-width: 30rem;
    border-radius: 1rem;
    /* overflow-y: auto; */
    position: relative;
    border: 1px solid black;
}

.reset-password-form header {
    background-color: #EA723C80;
    font-size: 1.5rem;
    border-radius: 1rem 1rem 0rem 0rem;
    font-weight: 600;
    width: 100%;
    height: fit-content;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-password-form form {
    padding: 1.5rem;
    box-sizing: border-box;
}

.reset-password-form .done {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}