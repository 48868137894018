.container {
    min-height: 7rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0.8rem;
    position: relative;
    align-items: center;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    border: 1px solid gray;
    transition: border ease-in-out 300ms;
    transition: background-color ease-in-out 300ms, height ease-in-out 300ms;
    cursor: default;
}

.container:hover, .container.active{
    border-style: dashed;
    border-color: #EA723C;
    background-color: #EA723C1A;
}

.uploadIconContainer {
    padding: 0.8rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000033;
    transition: background-color ease-in-out 300ms;
}

.container:hover .uploadIconContainer, .container.active .uploadIconContainer {
    background-color: #EA723C80;
}

.container:hover .uploadIconContainer path, .container.active .uploadIconContainer path {
    stroke: white;
}

.uploadText {
    display: flex;
    width: 100%;
    padding: 0.8rem;
    align-items: center;
    justify-content: center;
}

.container input {
    display: none;
}

.uploadText p {
    margin: 0 0.8rem;
}

.uploadText label {
    font-weight: 600;
    color: #EA723C;
    cursor: pointer;
}

.helperText {
    color: #6D6D6D;
    font-size: 0.85rem;
}

.errorText {
    color: rgb(240, 60, 60);
    font-weight: 600;
    font-size: 0.75rem;
}

.clearButton {
    position: absolute;
    right: 5px;
    top: 5px;
}

.previewContainer {
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 50svh;
    overflow-y: auto;
    box-sizing: border-box;
    flex-direction: column;
    scrollbar-gutter: stable both-edges;
}

.filesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); /* Creates a responsive grid with flexible columns */
    gap: 0.3rem;
    padding: 0.7rem;
}

.filePreview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 75px;
    height: 90px;
    padding: 0.3rem;
    font-size: 0.6rem;
    font-weight: 500;
    border-radius: 12px;
    background-color: #EA723C40;
    overflow-wrap: anywhere;
    position: relative;
}

.filename {
    text-align: center;
}

.filesize {
    color: #00000080;
}

.innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.addMoreContainer {
    width: 75px;
    height: 90px;
    display: flex;
    padding: 0.3rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: lightgray;
    border-radius: 12px;
}

.failedHeader {
    padding: 0.7rem 0rem 0rem 0.7rem;
    color: rgb(240, 60, 60);
    font-size: 1rem;
    font-weight: 600;
    font-variant: small-caps;
    text-transform: lowercase;
}

.failedPreview {
    background-color: #FFE2E3;
    color: black;
    font-weight: 500;
}