.link {
    color: gray;
    cursor: pointer;
    transition: color ease-in-out 300ms;
}

.link:hover {
    color: #EA723C;
}

.generate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.generate button {
    margin: 0rem;
    margin-left: 2rem;
}

.pasteInput input {
    border-style: dashed;
}

.generateBtn {
    height: 2.5rem;
    width: auto;
}

.generateBtn * {
    transition: fill ease-in-out 300ms, stroke ease-in-out 300ms;
}

.generateBtn:hover * {
    fill : #EA723C;
}

.generateBtn:hover rect {
    stroke: #EA723C;
}