.login-container {
    display: flex;
    width: 100svw;
    height: 100svh;
    overflow: hidden;
    background-image: url("/public/LoginBackground.jpg");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    user-select: none;
}

.login-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
}

.login-left-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 60%;
    justify-content: space-evenly;
}

.login-left-inner a {
    text-decoration: none;
    color: #1E1E1E;
    transition: color ease-in-out 200ms;
}
.login-left-inner a:hover {
    color: #EA723C;
}

.login-header {
    margin: 1rem 0 3rem 0;
}

.login-header h3 {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
}
.login-header p {
    font-size: 1.5rem;
    color: #1E1E1ECC;
    margin: 0;
}

.login-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 50%;
}

.login-quote {
    width: 100%;
    max-height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-right h3 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0;
}

@keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(20deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
}
  
.wave-emoji {
    display: inline-block;
    transform-origin: 70% 70%;
    animation: wave-animation 1s ease-in-out infinite;
    animation-delay: 2s;
} 

.login-image-container {
    display: flex;
    max-height: 70%;
    max-width: 100%;
}

.login-image {
    object-fit: cover;
}

.login-actions {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem;
}

.login-actions .btn {
    cursor: pointer;
    transition: color ease-in-out 300ms;
}

.login-actions .btn:hover {
    color: #EA723C;
}

@media screen and (max-width:768px) {
    .login-container {
        background-image: none;
    }

    .login-left {
        width: 100%;
    }

    .login-left-inner {
        height: 100%;
        max-width: 90%;
        width: max-content;
    }

    .login-image-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }

    .login-form {
        max-width: 500px;
    }

    .login-image {
        width: 350px;
        height: 200px;
    }

    .login-header {
        margin: 0.5rem 0 1rem 0;
    }

    .login-app-logo {
        margin: 0.5rem auto;
    }
}