.quickLinkCard {
    width: 100%;
    height: 100%;
    padding: 0.75rem;
    box-sizing: border-box;
    border-radius: 1.1rem;
    display: flex;
    text-decoration: none;
    flex-direction: column;
    transition: color ease-in-out 500ms, background-color ease-in-out 500ms, border-color ease-in-out 500ms;
}

.content {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.contentBox {
    width: 75%;
    font-size: 0.65rem;

}

.contentTitle {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0.5rem 0rem 0.3rem 0rem;
}

.arrowBox {
    width: 25%;
    height: fit-content;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.quickLinkCardIcon {
    height: 1.5rem;
    min-height: 1.25rem;
    width: fit-content;
    padding: 0.3rem;
    border-radius: 6px;
    transition: color ease-in-out 500ms, background-color ease-in-out 500ms, opacity ease-in-out 500ms;
}