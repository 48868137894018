.pie-chart-container {
    width: 100%;
    height: max-content;
}

.slider-container {
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 1rem;
}

.slider-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-keyword-input {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}