.custom-pagination-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -0.5rem;
    padding: 0rem 1rem;
    padding-top: 0.3rem;
    height: 50px;
    min-height: 60px;
    font-family: 'Montserrat';
    font-size: 0.875rem;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    user-select: none;
    border-top: 1px solid lightgray;
}

.custom-pagination-panel .left-side {
    display: flex;
    align-items: center;
}

.current-page {
    color : #6D6D6D;
    display: flex;
}

.current-page span {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px 5px;
    text-align: right;
    font-variant-numeric: tabular-nums;
}

.page-controller {
    display: flex;
}

.page-size p {
    margin: 0;
    white-space: nowrap;
    width: fit-content;
}

.page-size {
    color: #6D6D6D;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    width: max-content;
}

.page-controller .text-button {
    background-color: transparent;
    border: none;
    font: inherit;
    color: #EA723C;
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0.25rem;
    cursor: pointer;
}

.text-button:disabled {
    color:#6D6D6D;
    cursor: default;
}

.square-button {
    background-color: transparent;
    border: none;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid black;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
    cursor: pointer;
}

.square-button.page-number {
    background-color: transparent;
    border: none;
    transition: background-color ease-in-out 300ms, color ease-in-out 300ms;
}

.square-button.selected, .square-button.page-number:hover {
    background-color: #EA723C;
    color: white;
}

.square-button:disabled {
    background-color: #1E1E1E0F;
    border: none;
    cursor: default;
}

@media screen and (max-width:768px) {
    .page-size {
        margin-left: 0px;
    }

    .page-controller {
        align-items: center;
    }

    .text-button {
        height: 1.5rem;
        font-size: 0.75rem;
    }

    .square-button {
        height: 1.5rem;
        width: 1.5rem;
        margin: 0.1rem;
    }

    .custom-pagination-panel {
        width: 100%;
    }
}