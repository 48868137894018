.rank-and-analyze-container {
    width: inherit;
    height: 80svh;
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rank-and-analyze-container.mobile {
    width: 100%;
    height: 70svh;
    padding: 0; /*overwriting*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rank-and-analyze-header {
    width: 100%;
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: column;
}

.rank-and-analyze-header-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1rem 1rem 0rem 1rem;                 
}

.MuiAutocomplete-inputRoot.MuiInputBase-root {
    border-radius: 0.5rem;
}

.rna-headers-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    margin: auto 0 auto auto;
}

.rna-header-bar {
    width: 100%;
    display: flex;
    align-items: center;
}