.document-list-container {
    width: inherit;
    height: 80svh;
    display: flex;
    padding: 0rem 1rem;
    flex-direction: column;
    align-items: center;
}

.document-list-header-buttons {
    width: calc(100% - 1rem);
    margin: 1rem 0.5rem 0.8rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.document-list-search-bar {
    display: flex;
    padding: 0.5rem 0rem;
}

.document-list-header-helper {
    color: #797979;
    font-size: 0.9rem;
    padding-left: 1rem;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
}

.document-list-container hr {
    margin: 0;
    width: calc(100% + 2rem);
    height: 1px;
    margin-left: -1rem;
    border: none;
    border-top: 1px solid #B6BFCE96;
}

.document-list-header-helper p {
    margin: 0rem;
    font-size: 0.8rem;
    margin-left: 0.5rem;
}

.document-list-tabs {
    display: flex;
}

.document-list-tab {
    display: flex;
    align-items: center;
    margin: 0.2rem 0.4rem;
    cursor: pointer;
    letter-spacing: 1%;
    font-size: 0.9rem;
    font-weight: 800;
    transition: color ease-in-out 300ms;
}

.document-list-tab-number {
    margin: 0rem 0.2rem;
    padding: 0.1rem 0.2rem;
    border-radius: 0.3rem;
    font-size: 0.7rem;
    font-weight: 600;
    height: 1rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color ease-in-out 300ms, background-color ease-in-out 300ms;
}

.add-document-btn {
    color: #EA723C;
    text-transform: none;
    border: 1px solid #EA723C;
    border-radius: 12px;
    height: 2.5rem;
    min-width: 13rem;
}

.add-document-btn.disabled {
    color: lightgray;
    border: 1px solid lightgray;
}

.adddoc-svg-icon path {
    fill : #EA723C;
    transition: fill ease-in-out 300ms
}

.add-document-btn.disabled .adddoc-svg-icon path {
    fill: lightgray;
}

.add-document-btn:not(.disabled):hover {
    color: white;
}

.add-document-btn:not(.disabled):hover .adddoc-svg-icon path{
    fill : white
}

.filters-chips {
    height: fit-content;
}

@media screen and (max-width:768px) {
    .document-list-container {
        width: 100%;
        height: calc(100svh - 4rem - 2.5svh - 1rem - 3rem);
        padding: 0rem;
    }

    .document-list-header-buttons {
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .add-document-btn {
        height: 2rem;
        padding: 0.3rem;
        border-radius: 8px;
    }

    .document-list-header-helper {
        padding: 0 0.5rem;
    }

    .document-list-search-bar {
        width: 100%;
    }
}