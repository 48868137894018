.home-page-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    user-select: none;
}

.home-page-top {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-page-top-containers {
    height: 100%;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    background: #EA723C1A;
}

.home-page-top-containers.greetings {
    width: 75%;
    padding: 1rem 2rem;
}

.home-page-greetings-title {
    font-size: 1.8rem;
    font-weight: 800;
    color: #EA723C;
}

.home-page-top-containers.greetings p {
    font-size: 1.1rem;
    font-weight: 400;
}

.home-page-top-containers.plan-info {
    width: 25%;
    padding: 0.5rem 1rem;
}

.plan-info-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0.5rem 0rem;
}

.plan-info-item {
    margin: 0.3rem 0rem;
}

.plan-info-subtitle {
    font-size: 1rem;
    font-weight: 500;
}

.plan-info-subtitle + div {
    font-size: 0.95rem;
    font-weight: 400;
    color: #6D6D6D;
}

.home-page-bottom {
    width: 100%;
    height: 100%;
    margin: 1rem 0rem;
    max-height: 10rem;
    display: flex;
    align-items: center;
}

.home-page-cards {
    flex: 1;
    height: 100%;
    margin: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.home-page-cards-icon {
    width: 31px;
    height: 31px;
    padding: 0.5rem;
    border-radius: 8px;
    background-color: #EA723C33;
}

.home-page-card-title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0.3rem 0rem;
}

.home-page-card-title + div {
    font-size: 0.9rem;
    font-weight: 500;
    color: #00000080;
}

.home-page-card-title + div b{
    font-size: 1rem;
    font-weight: 600;
    color: black;
}


.home-header {
    /* text-transform: uppercase; */
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0.75rem;
}

.home-grid {
    display: grid;
    grid-template-columns: auto  auto auto;
    grid-template-rows: 2rem 2rem;
    margin: 0.5rem;
}
.home-grid-item {
    display: flex;
    align-items: center;
    color: #6D6D6D;
}

.home-hiw-item {
    color: #6D6D6D;
    display: flex;
    align-items: flex-start;
    margin: 1.2rem;
}

/*-------------------------------------- Mobile View --------------------------------------*/
@media screen and (max-width:768px) {
    .home-container {
        width: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
    }
    
    .home-page-container {
        padding: 0;
        width: 100%;
        height: calc(100svh - 4rem - 2.5svh - 1rem - 3rem);
        font-family: "Montserrat";
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .home-page-top {
        flex-direction: column;
        height: max-content;
    }

    .home-page-top-containers.greetings, .home-page-top-containers.plan-info {
        width: 100%;
        padding: 1.2rem;
        border-radius: 20px;
    }

    .home-page-bottom { 
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
    }

    .home-page-cards { 
        width: 100%;
        height: 10rem;
    }

    .home-grid {
        grid-template-columns: auto;
        box-sizing: border-box;
    }

    .home-grid-item {
        margin: 0.5rem 0;
    }
}