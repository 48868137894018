.help-and-support-container {
    width: inherit;
    height: 80svh;
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.help-and-support-header {
    width: calc(100% - 1rem);
    margin: 1rem 0.5rem;
    margin-bottom: 0rem;
}

.raise-ticket-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.75rem;
}

.raise-ticket-section h4 {
    color: #EA723C;
    margin: 0rem;
    font-size: 1.2rem;
}
.raise-ticket-section p {
    margin: 0.5rem 0rem;
    font-style: italic;
}

.ticket-button {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background-color: #EA723C;
    font: inherit;
    font-weight: 400;
    border-radius: 12px;
    border: none;
    cursor: pointer;
}

.faqs-item {
    width: 100%;
    border-bottom: 1px solid #1e1e1e72;
    cursor: pointer;
}

.faqs-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 600;
    color: #1E1E1E;
}

.faqs-answer {
    margin-bottom: 0.85rem;
}

.help-and-support-panels {
    box-sizing: border-box;
    padding: 1rem 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.help-and-support-panels.left {
    width: calc(40% - 0.5rem);
}
.help-and-support-panels.right {
    width: calc(60% - 0.5rem);
}

.has-left-title {
    color: #131313CC;
    font-weight: 600;
    margin: 0.5rem 0rem;
}

.has-left-faqs-title {
    font-size: 1.3rem;
    width: 100%;
    margin: 0.5rem 0rem;
    color: #EA723C;
    box-sizing: border-box;
}

.has-left-faqs {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    scrollbar-width: 0;
}

.has-left-faqs::-webkit-scrollbar {
    display: none;
}

.has-left-contact-us-card {
    color: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    padding: 1rem;
    margin-top: 1rem;
}

.has-left-contact-us-card h3 {
    text-transform: capitalize;
    color: white;
    font-size: 1rem;
    padding: 0;
    margin: 0;
}

.has-left-contact-us-card div {
    padding: 0;
    margin: 0.25rem;
    font-size: 0.9rem;
}

.has-left-contact-us-card a{ 
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 2.3rem;
    width: 8rem;
    max-width: 50%;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: inherit;
    border-radius: 12px;
    text-decoration: none;
    margin-top: 1rem;
}

.has-right-tickets-container {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: 0;
}

.has-right-tickets-container::-webkit-scrollbar {
    display: none;
}

.has-right-btn-container {
    margin: 0.5rem 0rem;
}

.add-ticket-btn {
    margin-top: 0.25rem;
    color: #EA723C;
    text-transform: none;
    border: 1px solid #EA723C;
    border-radius: 12px;
    height: 2.5rem;
}

.addticket-svg-icon path {
    fill : #EA723C;
    transition: fill ease-in-out 300ms
}

.add-ticket-btn:hover {
    color: white;
}

.add-ticket-btn:hover .addticket-svg-icon path{
    fill : white
}

.no-tickets-msg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticket-card {
    width: 100%;
    margin: 1rem 0rem;
    min-height: max-content;
    height: 10rem;
    max-height: 35%;
    display: flex;
    font-size: 0.9rem;
    flex-direction: column;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #F9F9F9;
    box-shadow: 0px 2px 4px 0px #0000001A;
    border: 1px solid #0000000A;
}

.ticket-header {
    display: flex;
    align-items: center;
    align-items: flex-end;
    justify-content: space-between;
}

.ticket-header .ticket-number {
    font-weight: 500;
}

.ticket-divider {
    color: #0000004D;
    height: 0.5rem;
}

.ticket-status {
    padding: 0.25rem 1rem;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 600;
    font-variant: all-petite-caps;
    border: 1px solid #0000000A;
}

.ticket-content {
    margin: 0.5rem 0rem;
    flex-grow: 1;
    box-sizing: border-box;
}

.ticket-content .subject {
    font-weight: 600;
}

.ticket-content .description {
    font-size: 0.85rem;
    font-weight: 400;
    margin: 0.25rem 0rem;
    color: #1E1E1E;
}

.ticket-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ticket-footer .datetime {
    font-weight: 500;
    font-size: 0.85rem;
    color: #1E1E1E80;
}

.ticket-footer .attachments {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

@media screen and (max-width:768px) {
    .has-right-tickets-container {
        min-height: 70svh;
    }

    .ticket-card {
        border-radius: 12px;
    }

    .help-and-support-panels.left, .help-and-support-panels.right {
        width: 100%;
        margin: 0.25rem 0rem;
    }
}