.analyze-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
    border-radius: 18px;
    margin: 0.5rem 0rem;
}

.analyze-header h3 {
    font-size: 1.25rem;
    opacity: 0.85;
    font-variant: all-petite-caps;
}

.analyze-header-scores {
    display: flex;
    padding: 0rem 0.5rem;
}

.analyze-header-scores sub {
    font-size: 0.75rem;
    margin-left: 1rem;
    font-style: italic;
}