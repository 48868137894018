.wordsContainer {
    overflow: hidden;
    height: 2.5rem;
}

.wordsBox {
    margin: 0;
    font-size: 2.5rem;
    color: #EA723C;
}

@keyframes slideInUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
}
  
@keyframes slideOutUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
}
  
.circularSlideInUp {
    animation: slideInUp 0.5s forwards;
}
  
.circularSlideOutUp {
    animation: slideOutUp 0.5s forwards;
}
