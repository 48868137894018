.action-svg-icon * {
    transition: fill ease-in-out 300ms, stroke ease-in-out 300ms;
}

.action-svg-icon path:not(.fill-based) {
    stroke: #232322;
}
.action-svg-icon rect, .action-svg-icon path.fill-based {
    fill: #232322;
}

.action-btn:hover .action-svg-icon path:not(.fill-based) {
    stroke: #EA723C;
}
.action-btn:hover .action-svg-icon rect, .action-btn:hover .action-svg-icon path.fill-based {
    fill: #EA723C;
}
