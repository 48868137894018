.raise-a-ticket-attachments {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.raise-a-ticket-input-label {
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    cursor: pointer;
    transition: color ease-in-out 300ms;
}

.raise-a-ticket-input-label span {
    color: #1E1E1E8D;
    transition: color ease-in-out 300ms;
}

.raise-a-ticket-input-label:hover, .raise-a-ticket-input-label:hover span {
    color: #EA723CDD;
}

.raise-a-ticket-input-label svg * {
    transition: stroke ease-in-out 300ms;
}

.raise-a-ticket-input-label:hover svg * {
    stroke: #EA723CDD;
}

.attachments-container {
    max-height: 10rem;
    width: 100%;
    overflow-y: auto;
}

.attachment-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    box-sizing: border-box;
}

.attachment-icon {
    min-width: 25px;
    min-height: 25px;
    padding: 0.3rem;
    border-radius: 8px;
    background-color: #EA723C33;
    border: 1px solid #00000010;
}

.attachment-item-details {
    display: flex;
    align-items: center;
    max-width: 80%;
    overflow-x: clip;
    text-overflow: ellipsis;
}

.attachment-item-filename {
    display: flex;
    font-size: 0.9rem;
    font-weight: 500;
    flex-direction: column;
    margin: 0rem 1rem;
}

.attachment-item-filename .file-detail {
    font-size: 0.75rem;
    color: #00000080;
}
.attachment-item-filename .file-detail.error {
    font-size: 0.75rem;
    color: #FF0000D3;
}